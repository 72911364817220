import './Footer.css';
const Footer = () =>{
    return(
        <div className="sectionWrapper footerWrapper">
            <section id="Footer" className='container section'>
                <p>Diego Hartmann © All rights reserved</p>
            </section>
        </div>
    )
}

export default Footer;